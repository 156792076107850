import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { ReactComponent as SendIcon } from '../../assets/svg/send-icon.svg'
import { ReactComponent as UploadIcon } from '../../assets/svg/upload-icon-2.svg'
import AddIcon from '@mui/icons-material/Add'
import { throttle } from 'lodash'
import { default as VisibilityIcon } from '@mui/icons-material/Visibility'
import {
	Checkbox,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { ReactComponent as AppsIcon } from '../../assets/varys-applist-icon-2.svg'
import { ReactComponent as FilterRemoveIcon } from '../../assets/varys-filter-remove.svg'
import { ReactComponent as MediaPlanIcon } from '../../assets/varys-media-plan-icon.svg'
import {
	convertPathName,
	getIntegerAndFloatColumns,
	roundColumnValuesNumbers,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import {
	SimpleActionsButton,
	StatusButton,
	SubmitButton,
	UnborderedButton,
} from './Buttons'
import {
	DateFilterSearchComponent,
	FilterSearchComponent,
} from './SelectableInputs'
import { StaticChipInputList } from './StaticChipInput'
import HandshakeIcon from '@mui/icons-material/Handshake'
import { capitalizeFirstLetter } from '../../utils/helpers/tableHelper'
import styled from 'styled-components'
import { Box, TextField, IconButton, InputAdornment } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import {
	StyledTableBodyCell,
	StyledTableRow,
} from '../reports/common/CustomReportTable'

import { useLogPropChanges } from '../../utils/helpers/RenderChecker'
import { reportColumnMap } from '../../models/reports.interface'
export const TableComponent = (props: {
	style?: any
	setLoading: any
	columns: GridColDef[]
	rows: any[]
	setPageSize: any
	pageSize: any
	rowsPerPageOptions: Array<number | { label: string; value: number }>
	onRowClick?: any
	experimentalFeatures?: any
	onRowEditStop?: any
	onRowEditCommit?: any
	commitCellChange?: any
	filterStatus?: any
	setFilterStatus?: any
	setSearch?: any
	search?: any
	handlePopUp?: any
	downloadButtonOptions?: any
	uploadButtonOptions?: any
	handlePasswordVisibility?: any
	mediaPlanHandler?: any
	handshakeHandler?: any
	onCellClick?: any
	sortModel?: any
	rowHeight?: any
	onSortModelChange?: any
	filterHandler?: any
	setFilterHander?: any
	customFilter?: any
	setCustomFilter?: any
	currentFilters?: any
	applyFilter?: any
	filterLabel?: any
	optionsFilters?: any
	mainFilterOptions?: any
	rowModesModel?: any
	handleRowModesModelChange?: any
	selector?: any
	isAutoSwitch?: any
	appIdList?: any[]
	appId?: any
	setAppId?: any
	selectorProp?: any
	processRowUpdate?: any
	handleRowEditStop?: any
	handleDoubleCellClick?: any
	handleCellKeyDown?: any
	handleCellFocusOut?: any
	handleCellOnFocus?: any
	monthFilterArray?: any
	setMonthFilterArray?: any
	yearFilterArray?: any
	setYearFilterArray?: any
	downloadTemplate?: any
	pagination?: boolean
	paginationMode?: 'client' | 'server'
	fileInputClickHandler?: any
	treeData?: boolean
	getTreeDataPath?: any
	groupingColDef?: any
	filterModel?: any
	onFilterModelChange?: any
	sendEmail?: any
	financeLastSend?: any
	financeFinalSent?: boolean
	getRowClassName?: any
	customSx?: any
	customMessage?: any
	columnVisibility?: any
	autoswitch?: any
	pinnedBottomRow?: any
	apiRef?: any
	disableColumnMenu?: boolean
	searchWithTimer?: boolean
	checkboxButtonValue?: boolean
	pageSizeOptions?: Array<number | { label: string; value: number }>

	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
	checkboxButtonLabel?: string
	downloadButtonFolderOptions?: any
	infoOpened?: boolean
	setInfoOpened?: Dispatch<SetStateAction<boolean>>
	triggerExtraBolFunction?: Function
	triggerExtraBolFunctionValue?: boolean
	columnHeaderHeight?: number

	extraBolFunctionLabel?: any
	presetSearch?: string
	noToolbar?: boolean
	loading?: boolean
	dateFilter?: any
	setDateFilter?: any
	dateFilterHandler?: any
	setDateFilterHandler?: any
	getRowHeight?: any
	customTableButtons?: any
}) => {
	const theme = useTheme()
	// useEffect(() => {
	// 	props.setLoading(false)
	// })

	const [filterButtonEl, setFilterButtonEl] =
		React.useState<HTMLButtonElement | null>(null)
	const [filterSearch, setFilterSearch] = useState([])

	const [showClearAllFiltersButton, setShowClearAllFiltersButton] =
		useState(false)
	const filterRef = useRef(null)
	const [position, setPosition] = useState({ x: 0, y: 0 })
	const [hoverRow, setHoverRow] = useState(false)
	const [currentHoveredRow, setCurrentHoveredRow] = useState<any>({})
	const pageSizeOptions = props.pageSizeOptions || [
		{ value: 30, label: '30' },
		{ value: 100, label: '100' },
		{ value: 200, label: '200' },
		{ value: props.rows.length, label: 'All' },
	]

	let handlePopUp = props.handlePopUp
	let handlePopUpDisabled = false
	if (props.handlePopUp) {
		if (typeof props.handlePopUp.onClick === 'function') {
			handlePopUp = props.handlePopUp.onClick
		}
		if (typeof props.handlePopUp.disabled === 'boolean') {
			handlePopUpDisabled = props.handlePopUp.disabled
		}
	}

	// const applyFilter = () => {
	// 	if (filterSearch.length === 0) {
	// 		props.customFilter[
	// 			props.currentFilters as keyof typeof props.customFilter
	// 		] = filterSearch
	// 	}
	// 	props.applyFilter(props.rows)
	// }
	const clearFilter = () => {
		if (props.customFilter) {
			props.customFilter[
				props.currentFilters as keyof typeof props.customFilter
			] = []
		}

		setFilterSearch([])
		props.applyFilter()
	}
	const clearAllFilters = useCallback(() => {
		if (props.customFilter) {
			for (const key in props.customFilter) {
				props.customFilter[key as keyof typeof props.customFilter] = []
			}
		}
		setFilterSearch([])
		props.applyFilter()
	}, [])

	const handleClickOutside = (event: any) => {
		if (
			filterRef.current &&
			!(filterRef.current as any).contains(event.target) &&
			event.target.tagName.toLowerCase() !== 'li' &&
			event.target.role !== 'option'
		) {
			if (props.setFilterHander) props.setFilterHander(false)
			if (props.setDateFilterHandler) {
				let clickIsOnDateComponent = false
				for (const className of event.srcElement.classList) {
					if (
						className === 'MuiPickersDay-root' ||
						className === 'MuiSvgIcon-root'
					) {
						clickIsOnDateComponent = true
						break
					}
				}
				if (clickIsOnDateComponent === false) {
					props.setDateFilterHandler(false)
				}
			}
		}
	}
	useEffect(() => {
		//we need to clear the values once the filter is unselected
		if (props.dateFilterHandler === false && props.filterHandler === false) {
			setFilterSearch([])
		}
	}, [props.filterHandler, props.dateFilterHandler])

	const calculateLeftPosition = () => {
		let result = 0
		for (let i = 0; i < props.columns.length; i++) {
			if (props.columns[i].field === props.currentFilters) {
				result = i
				break
			}
		}
		return (result * 90) / props.columns.length + '%'
	}
	useEffect(() => {
		if (props.customFilter) {
			for (const key of Object.keys(props.customFilter)) {
				if (props.customFilter[key].length > 0) {
					setShowClearAllFiltersButton(true)
					break
				}
			}
		}
	}, [props.customFilter])
	useEffect(() => {
		if (props.customFilter) {
			if (filterSearch && filterSearch.length !== 0) {
				props.customFilter[props.currentFilters] = filterSearch
				setShowClearAllFiltersButton(true)
			} else {
				if (props.filterHandler || props.dateFilterHandler) {
					props.customFilter[props.currentFilters] = []
					props.applyFilter()
					let changed = false
					for (const key of Object.keys(props.customFilter)) {
						if (
							props.customFilter[key].length > 0 &&
							key !== props.currentFilters
						) {
							setShowClearAllFiltersButton(true)
							changed = true
							break
						}
					}
					// setFilterSearch([])
					changed || setShowClearAllFiltersButton(false)
				}
			}
		}

		// console.log(filterSearch, props.customFilter)
	}, [filterSearch])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])
	useEffect(() => {
		if (props.customMessage) {
			const handleMouseMove = throttle((e: any) => {
				//if there's ul with role listbox it means the filter is open and we don't want to have hover reactions otherwise it will
				//re-render
				if (!document.querySelector("ul[role='listbox']")) {
					// Subtract part of the div position in order to be closer to the cursor
					setPosition({
						x: e.clientX - 20,
						y: e.clientY - 170,
					})
				}
			})
			document.addEventListener('mousemove', handleMouseMove)
			return () => {
				document.removeEventListener('mousemove', handleMouseMove)
			}
		}
	}, [])
	const renderCustomMessage = () => {
		if (props.customMessage) {
			return props.customMessage(currentHoveredRow, position)
		} else {
			return <></>
		}
	}

	const filterSearchComponentArray = (filterKeysList: any) => {
		let wholeList: any = {}
		// console.log(
		// 	'filter search',
		// 	props.currentFilters,
		// 	filterSearch,
		// 	props.customFilter,
		// )

		for (let e of filterKeysList)
			wholeList[e] = (
				<FilterSearchComponent
					style={{
						width: '95%',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
						height: 'inherit',
					}}
					key={'FilterSearchComponent___' + e}
					setSearch={setFilterSearch}
					search={filterSearch}
					applyFilter={props.applyFilter}
					filterLabel={props.filterLabel}
					currentFilters={props.currentFilters}
					clearFilter={clearFilter}
					optionsFilters={props.optionsFilters}
					setFilterHander={props.setFilterHander}
					filtersKey={e}
					customFilter={props.customFilter}
				/>
			)
		// console.log(wholeList)

		return wholeList[props.currentFilters]
	}
	const dateFilterSearchComponentArray = (filterKeysList: any) => {
		let wholeList: any = {}
		// console.log(
		// 	'filter date',
		// 	props.currentFilters,
		// 	filterSearch,
		// 	props.customFilter,
		// )

		for (let e of filterKeysList)
			wholeList[e] = (
				<DateFilterSearchComponent
					style={{
						width: '95%',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
						height: 'inherit',
					}}
					key={'FilterSearchComponent___' + e}
					setSearch={setFilterSearch}
					search={filterSearch}
					applyFilter={props.applyFilter}
					filterLabel={props.filterLabel}
					currentFilters={props.currentFilters}
					clearFilter={clearFilter}
					setFilterHander={props.setFilterHander}
					filtersKey={e}
					customFilter={props.customFilter}
				/>
			)
		// console.log(wholeList)

		return wholeList[props.currentFilters]
	}
	let filterKeysList: any = []
	let datefilterKeysList: any = []
	if (props.customFilter) {
		filterKeysList = Object.keys(props.customFilter)
	}
	if (props.dateFilter) {
		datefilterKeysList = Object.keys(props.dateFilter)
	}
	let rowHeight = props.rowHeight

	let getRowHeight = () => props.rowHeight
	if (props.rowHeight === 'auto') {
		rowHeight = undefined
		getRowHeight = () => 'auto'
	}
	if (!props.rowHeight || typeof props.rowHeight !== 'number') {
		rowHeight = 55
	}
	if (props.triggerExtraBolFunctionValue) {
		getRowHeight = () => 80
	}
	return (
		<>
			<DataGridPro
				initialState={{
					sorting: {
						sortModel: [
							props.sortModel
								? props.sortModel
								: { field: 'createdAt', sort: 'desc' },
						],
					},
					pagination: { paginationModel: { pageSize: 30 } },
					columns: { columnVisibilityModel: props.columnVisibility },
				}}
				rows={props.rows}
				columns={props.columns}
				pagination={props.pagination !== undefined ? props.pagination : true}
				paginationMode={props.paginationMode || 'client'}
				pageSizeOptions={pageSizeOptions}
				onCellEditStop={props.onRowEditStop}
				rowHeight={rowHeight}
				apiRef={props.apiRef}
				// headerHeight={50}
				loading={props.loading}
				sortModel={props.sortModel}
				slots={{
					toolbar: props.noToolbar ? undefined : TableToolbar,
				}}
				editMode={'row'}
				rowModesModel={props.rowModesModel}
				onRowModesModelChange={props.handleRowModesModelChange}
				disableColumnMenu={props.disableColumnMenu || false}
				slotProps={{
					panel: {
						anchorEl: filterButtonEl,
					},
					toolbar: {
						setFilterButtonEl,
						filterStatus: props.filterStatus,
						setFilterStatus: props.setFilterStatus,
						setSearch: props.setSearch,
						search: props.search,
						handlePopUp: handlePopUp,
						handlePopUpDisabled: handlePopUpDisabled,
						downloadButtonOptions: props.downloadButtonOptions,
						uploadButtonOptions: props.uploadButtonOptions,
						handlePasswordVisibility: props.handlePasswordVisibility,
						mediaPlanHandler: props.mediaPlanHandler,
						handshakeHandler: props.handshakeHandler,
						filterHandler: props.filterHandler,
						customFilter: props.customFilter,
						clearAllFilters: clearAllFilters,
						showClearAllFiltersButton: showClearAllFiltersButton,
						setShowClearAllFiltersButton: setShowClearAllFiltersButton,
						mainFilterOptions: props.mainFilterOptions,
						setAppId: props.setAppId,
						appId: props.appId,
						selector: props.selector,
						selectorProp: props.selectorProp,
						monthFilterArray: props.monthFilterArray,
						setMonthFilterArray: props.setMonthFilterArray,
						yearFilterArray: props.yearFilterArray,
						setYearFilterArray: props.setYearFilterArray,
						downloadTemplate: props.downloadTemplate,
						fileInputClickHandler: props.fileInputClickHandler,
						sendEmail: props.sendEmail,
						financeLastSend: props.financeLastSend,
						financeFinalSent: props.financeFinalSent,
						applyFilter: props.applyFilter,
						autoswitch: props.autoswitch,
						searchWithTimer: props.searchWithTimer,
						checkboxButtonValue: props.checkboxButtonValue,
						setCheckboxButtonValue: props.setCheckboxButtonValue,
						checkboxButtonLabel: props.checkboxButtonLabel,
						downloadButtonFolderOptions: props.downloadButtonFolderOptions,
						infoOpened: props.infoOpened,
						setInfoOpened: props.setInfoOpened,
						triggerExtraBolFunction: props.triggerExtraBolFunction,
						triggerExtraBolFunctionValue: props.triggerExtraBolFunctionValue,
						extraBolFunctionLabel: props.extraBolFunctionLabel,
						presetSearch: props.presetSearch,
						dateFilterHandler: props.dateFilterHandler,
						customTableButtons: props.customTableButtons,
					},
					cell: {
						onBlur: props.handleCellFocusOut,
						onFocus: props.handleCellOnFocus,
					},
					row: {
						onMouseEnter: (event) => {
							if (
								event.currentTarget.dataset.id &&
								!document.querySelector("ul[role='listbox']")
							) {
								const id = parseInt(event.currentTarget.dataset.id)
								const row = props.rows.find((el: any) => el.id === id)
								setCurrentHoveredRow(row)
								setHoverRow(true)
							}
						},
						onMouseLeave: () => {
							setHoverRow(false)
						},
					},
				}}
				getRowHeight={getRowHeight}
				onCellClick={props.onCellClick}
				onSortModelChange={props.onSortModelChange}
				onCellDoubleClick={props.handleDoubleCellClick}
				onCellKeyDown={props.handleCellKeyDown}
				treeData={props.treeData}
				getTreeDataPath={props.getTreeDataPath}
				groupingColDef={props.groupingColDef}
				filterModel={props.filterModel}
				onFilterModelChange={props.onFilterModelChange}
				getRowClassName={(params) => {
					if (props.getRowClassName) {
						return props.getRowClassName(params)
					} else {
						return props.treeData &&
							params.row.hierarchy &&
							params.row.hierarchy.length > 1
							? 'treed-row'
							: ''
					}
				}}
				columnHeaderHeight={props.columnHeaderHeight || 40}
				sx={{
					'& .MuiDataGrid-withBorderColor': {
						borderBottom: `1px solid ${theme.colors.base.grey2}`,
					},
					'& .MuiDataGrid-columnHeaders': {
						borderBottom: `1px solid ${theme.colors.base.grey3} !important`,
						borderTop: `1px solid ${theme.colors.base.grey3} !important`,
					},
					'& .MuiDataGrid-columnHeaders:focus': {
						outline: 'none',
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'none',
					},
					'& .MuiDataGrid-cell--editing': {
						color: theme.colors.text.titles,
						'& .MuiInputBase-input': {
							color: theme.colors.text.titles,
						},
					},
					'& .MuiDataGrid-toolbarContainer': {
						padding: props.noToolbar ? '0px !important' : 'inherit',
						margin: props.noToolbar ? '0px !important' : 'inherit',
					},
					'& .MuiDataGrid-pinnedRows': {
						backgroundColor: theme.colors.base.white,
						border: '1px solid ' + theme.colors.base.grey900,
					},
					'& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
						background: theme.colors.base.white2 + ' !important',
					},
					...props.customSx,
				}}
				processRowUpdate={props.processRowUpdate}
				pinnedRows={{
					bottom: props.pinnedBottomRow,
				}}
				style={{
					border: 'none',
					width: '95vw',
					...props.style,
				}}
			/>
			<div ref={filterRef}>
				{props.filterHandler && (
					<div
						style={{
							background: theme.colors.base.grey50,
							width: '20%',
							padding: '0px 18px 0px 18px',
							minWidth: '400px',
							height: 'auto',
							display: 'flex',
							position: 'absolute',
							top: '11%',
							left: calculateLeftPosition(),
							boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.15)',
							borderRadius: '6px',
							alignItems: 'center',
							zIndex: 12,
						}}
						className={'table-component'}
					>
						{filterSearchComponentArray(filterKeysList)}
					</div>
				)}
				{props.dateFilterHandler && (
					<div
						style={{
							background: theme.colors.base.grey50,
							width: '20%',
							padding: '0px 18px 0px 18px',
							minWidth: '400px',
							height: 'auto',
							display: 'flex',
							position: 'absolute',
							top: '11%',
							left: calculateLeftPosition(),
							boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.15)',
							borderRadius: '6px',
							alignItems: 'center',
						}}
						className={'table-component'}
					>
						{dateFilterSearchComponentArray(datefilterKeysList)}
					</div>
				)}
				{hoverRow &&
					currentHoveredRow &&
					!document.querySelector("ul[role='listbox']") &&
					renderCustomMessage()}
			</div>
		</>
	)
}

export const TableToolbar: React.FunctionComponent<{
	setFilterButtonEl: React.Dispatch<
		React.SetStateAction<HTMLButtonElement | null>
	>
	filterStatus?: any
	setFilterStatus?: any
	setSearch: any
	search: any
	handlePopUp: any
	handlePopUpDisabled: boolean
	downloadButtonOptions: any
	uploadButtonOptions: any
	handlePasswordVisibility: any
	mediaPlanHandler?: any
	handshakeHandler?: any
	filterHandler?: any
	customFilter?: any
	clearAllFilters?: any
	showClearAllFiltersButton?: boolean
	setShowClearAllFiltersButton?: any
	mainFilterOptions: any
	isAutoSwitch?: any
	appIdList?: any[]
	appId?: any
	setAppId?: any
	selector?: any
	selectorProp?: any
	monthFilterArray?: any
	setMonthFilterArray?: any
	yearFilterArray?: any
	setYearFilterArray?: any
	downloadTemplate?: any
	fileInputClickHandler?: any
	sendEmail?: any
	financeLastSend?: any
	financeFinalSent?: boolean
	hideBlockedUsersButton?: boolean
	applyFilter: any
	clearFilter: any
	autoswitch?: any
	searchWithTimer?: boolean
	checkboxButtonValue?: boolean
	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
	checkboxButtonLabel?: string
	downloadButtonFolderOptions?: any
	infoOpened?: boolean
	setInfoOpened?: Dispatch<SetStateAction<boolean>>
	triggerExtraBolFunction?: Function
	triggerExtraBolFunctionValue?: boolean
	extraBolFunctionLabel?: any
	presetSearch?: string
	dateFilterHandler?: any
	customTableButtons?: any
}> = React.memo(
	({
		setFilterButtonEl,
		filterStatus,
		setFilterStatus,
		setSearch,
		search,
		handlePopUp,
		handlePopUpDisabled,
		downloadButtonOptions,
		uploadButtonOptions,
		handlePasswordVisibility,
		mediaPlanHandler,
		handshakeHandler,
		isAutoSwitch,
		appIdList,
		appId,
		setAppId,
		selector,
		selectorProp,
		filterHandler,
		customFilter,
		clearAllFilters,
		showClearAllFiltersButton,
		setShowClearAllFiltersButton,
		mainFilterOptions,
		monthFilterArray,
		setMonthFilterArray,
		yearFilterArray,
		setYearFilterArray,
		downloadTemplate,
		fileInputClickHandler,
		sendEmail,
		financeLastSend,
		financeFinalSent,
		hideBlockedUsersButton,
		applyFilter,
		clearFilter,
		autoswitch,
		searchWithTimer,
		checkboxButtonValue,
		setCheckboxButtonValue,
		checkboxButtonLabel,
		downloadButtonFolderOptions,
		infoOpened,
		setInfoOpened,
		triggerExtraBolFunction,
		triggerExtraBolFunctionValue,
		extraBolFunctionLabel,
		presetSearch,
		dateFilterHandler,
		customTableButtons,
	}) => {
		//TODO: use this function if you have issues with re-render.
		// useLogPropChanges({
		// 	setFilterButtonEl,
		// 	filterStatus,
		// 	setFilterStatus,
		// 	setSearch,
		// 	search,
		// 	handlePopUp,
		// 	handlePopUpDisabled,
		// 	downloadButtonOptions,
		// 	uploadButtonOptions,
		// 	handlePasswordVisibility,
		// 	mediaPlanHandler,
		// 	handshakeHandler,
		// 	isAutoSwitch,
		// 	appIdList,
		// 	appId,
		// 	setAppId,
		// 	selector,
		// 	selectorProp,
		// 	filterHandler,
		// 	customFilter,
		// 	clearAllFilters,
		// 	showClearAllFiltersButton,
		// 	setShowClearAllFiltersButton,
		// 	mainFilterOptions,
		// 	monthFilterArray,
		// 	setMonthFilterArray,
		// 	yearFilterArray,
		// 	setYearFilterArray,
		// 	downloadTemplate,
		// 	fileInputClickHandler,
		// 	sendEmail,
		// 	financeLastSend,
		// 	financeFinalSent,
		// 	hideBlockedUsersButton,
		// 	applyFilter,
		// 	clearFilter,
		// 	autoswitch,
		// 	searchWithTimer,
		// 	checkboxButtonValue,
		// 	setCheckboxButtonValue,
		// 	checkboxButtonLabel,
		// 	downloadButtonFolderOptions,
		// 	infoOpened,
		// 	setInfoOpened,
		// 	triggerExtraBolFunction,
		// 	triggerExtraBolFunctionValue,
		// 	extraBolFunctionLabel,
		// 	presetSearch,
		// 	dateFilterHandler,
		// 	customTableButtons,
		// })

		const theme = useTheme()
		const possibleCampaignFilter = mainFilterOptions
			? mainFilterOptions
			: ['Both', 'Active', 'Disabled']
		// const [filterTitleColor, setFilterTitleColor] = useState(theme.colors.black)
		const activeFilterHandler = (
			possibleCampaign: any,
			filterCurrent: any,
			setFilter: any,
		) => {
			let maxNumber = possibleCampaign.length
			const number = filterCurrent + 1
			if (number === maxNumber) setFilter(0)
			else setFilter(number)
		}

		const handleClearAllFilters = () => {
			clearAllFilters()
			if (setMonthFilterArray) {
				setMonthFilterArray([])
			}
			if (setYearFilterArray) {
				setYearFilterArray([])
			}
			applyFilter()
			setShowClearAllFiltersButton(false)
		}
		const [downloadFocus, setDownloadFocus] = useState(false)
		const [uploadFocus, setUploadFocus] = useState(false)
		const [sendFocus, setSendFocus] = useState(false)
		const [changeErrorButtonColor, setChangeErrorButtonColor] = useState(false)
		const [yearOptions, setYearOptions] = useState<string[]>([])

		useEffect(() => {
			if (yearOptions.length === 0) {
				let startingYear = 2023
				const currentYear = moment().year()
				const nextYear = moment().add(1, 'y').year()
				const arr: (string | number)[] = [startingYear]
				while (startingYear !== nextYear) {
					startingYear++
					arr.push(startingYear)
				}
				for (const indx in arr) {
					if (currentYear === arr[indx]) {
						arr[indx] = 'This Year'
					} else {
						arr[indx] = arr[indx].toString()
					}
				}
				setYearOptions(arr.reverse() as string[])
			}
		}, [])

		return (
			<GridToolbarContainer
				style={{
					gap: '12px',
					display: 'flex',
					justifyContent: 'flex-start',
					marginBottom: 18,
				}}
				key='toolbar'
			>
				{filterStatus !== undefined && (
					<StatusButton
						activeFilterHandler={activeFilterHandler}
						possibleCampaignFilter={possibleCampaignFilter}
						filterStatus={filterStatus}
						setFilterStatus={setFilterStatus}
					/>
				)}
				{typeof handlePopUp !== 'undefined' && autoswitch === undefined && (
					<UnborderedButton
						onClick={(e: any) => handlePopUp(e)}
						label={'Add' + convertPathName(window.location.pathname)}
						icon={<AddIcon />}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.body,
						}}
						// iconStyle={{ height: '16px' }}
					/>
				)}
				{selector && selectorProp && (
					<UnborderedButton
						onClick={() => selector(selectorProp)}
						label={'Apps'}
						icon={
							<AppsIcon
								style={{ color: theme.colors.text.titles, scale: '1' }}
							/>
						}
						style={{ color: theme.colors.text.titles }}
					/>
				)}
				{mediaPlanHandler !== undefined && (
					<UnborderedButton
						onClick={mediaPlanHandler}
						label={'Media Plan'}
						icon={
							<MediaPlanIcon
								style={{ color: theme.colors.text.titles, scale: '1' }}
							/>
						}
						style={{ color: theme.colors.text.titles }}
					/>
				)}
				{handshakeHandler !== undefined && (
					<UnborderedButton
						onClick={handshakeHandler}
						label={'Handshake'}
						icon={<HandshakeIcon style={{ width: '20px' }} />}
						style={{ color: theme.colors.text.titles }}
					/>
				)}

				{triggerExtraBolFunction !== undefined && (
					<UnborderedButton
						onClick={() => triggerExtraBolFunction()}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.body,
						}}
						icon={
							triggerExtraBolFunctionValue
								? extraBolFunctionLabel['iconTrue']
								: extraBolFunctionLabel['iconFalse']
						}
					>
						{
							extraBolFunctionLabel[
								triggerExtraBolFunctionValue as unknown as keyof typeof extraBolFunctionLabel
							]
						}
					</UnborderedButton>
				)}
				{setCheckboxButtonValue !== undefined && (
					<>
						<UnborderedButton
							onClick={() => setCheckboxButtonValue(!checkboxButtonValue)}
							style={{
								// boxShadow: 'none',
								width: checkboxButtonValue === undefined ? 80 : 120,
								display: 'flex',
								alignItems: 'center',
								height: 20,
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
							}}
							onMouseEnter={() => setChangeErrorButtonColor(true)}
							onMouseLeave={() => setChangeErrorButtonColor(false)}
							isActive={checkboxButtonValue}
							icon={
								<Checkbox
									checked={checkboxButtonValue}
									onChange={() => {}}
									disabled
									icon={
										<CheckBoxOutlineBlankIcon
											style={{
												scale: '0.7',
												color: theme.colors.base.green300,
											}}
										/>
									}
									style={{ padding: '0px' }}
									checkedIcon={
										<CheckBoxIcon
											style={{
												scale: '0.7',
												color: theme.colors.base.green300,
											}}
										/>
									}
								/>
							}
						>
							{checkboxButtonLabel}
						</UnborderedButton>
					</>
				)}
				{hideBlockedUsersButton && (
					<SubmitButton
						onClick={handlePopUp}
						style={{
							// boxShadow: 'none',
							width: '80px',
							height: '30px',
							marginTop: '9px',
							fontSize: '12px',
						}}
					>
						Blocked
					</SubmitButton>
				)}
				<div
					style={{
						marginLeft: 'auto',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginRight: 40,
					}}
				>
					{customFilter !== undefined && (
						<UnborderedButton
							onClick={handleClearAllFilters}
							icon={
								<FilterRemoveIcon
									style={{ color: theme.colors.text.titles, scale: '1' }}
								/>
							}
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								width: 130,
								height: 20,
								// marginLeft: 'auto',
							}}
						>
							Clear Filters
						</UnborderedButton>
					)}
					{downloadButtonOptions !== undefined && (
						<SimpleActionsButton
							label={'Download'}
							options={downloadButtonOptions}
							icon={<DownloadIcon style={{ width: '14px' }} />}
							style={{
								color: theme.colors.text.titles,
								fontSize: theme.font.size.body,
							}}
						/>
					)}
					{uploadButtonOptions !== undefined && (
						<SimpleActionsButton
							label={'Upload'}
							options={uploadButtonOptions}
							icon={
								<UploadIcon
									style={{ width: '14px', color: theme.colors.text.titles }}
								/>
							}
							style={{
								color: theme.colors.text.titles,
								fontSize: theme.font.size.body,
							}}
						/>
					)}
					{handlePasswordVisibility !== undefined && (
						<UnborderedButton
							// fullWidth
							style={{
								// width: '130px',
								height: '30px',
								// marginTop: '9px',
								fontSize: '12px',
								color: theme.colors.text.titles,
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'center',
							}}
							onClick={() => {
								handlePasswordVisibility()
							}}
							icon={<VisibilityIcon />}
						>
							View Password
						</UnborderedButton>
					)}
					{setInfoOpened && (
						<UnborderedButton
							onClick={() => setInfoOpened(!infoOpened)}
							icon={<InfoOutlinedIcon></InfoOutlinedIcon>}
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								height: 20,
							}}
						>
							Info
						</UnborderedButton>
					)}
					{yearFilterArray && (
						<div
							style={{
								display: 'flex',
								maxWidth: '420px',
								// overflow: 'auto',
							}}
						>
							<StaticChipInputList
								onChange={setYearFilterArray}
								options={yearOptions}
								value={yearFilterArray}
								openWithClick={true}
								style={
									{
										height: 'inherit',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										margin: '8px',
										width: 'auto',
										minWidth: '100px',
										// marginTop: '25px',
									} as any
								}
								onlyOptionsAllowed
								placeholder={'Year'}
							/>
						</div>
					)}

					{monthFilterArray && (
						<div
							style={{
								display: 'flex',
								maxWidth: '420px',
							}}
						>
							<StaticChipInputList
								onChange={setMonthFilterArray}
								options={[
									'January',
									'February',
									'March',
									'April',
									'May',
									'June',
									'July',
									'August',
									'September',
									'October',
									'November',
									'December',
								]}
								value={monthFilterArray}
								openWithClick={true}
								style={
									{
										height: 'inherit',
										display: 'flex',
										alignItems: 'center',
										// justifyContent: 'center',
										margin: '8px',
										width: 'auto',
										minWidth: '140px',
										// marginTop: '25px',
									} as any
								}
								onlyOptionsAllowed
								placeholder={'Month'}
							/>
						</div>
					)}
					{downloadTemplate && (
						<UnborderedButton
							onClick={downloadTemplate}
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								height: 20,
								display: 'flex',
								// marginTop: 29,
							}}
							icon={<DownloadIcon style={{ width: '14px' }} />}
						>
							Download
						</UnborderedButton>
					)}
					{downloadButtonFolderOptions && (
						<SimpleActionsButton
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								height: 20,
								display: 'flex',
							}}
							options={downloadButtonFolderOptions}
							label={'Download'}
							icon={<DownloadIcon style={{ width: '14px' }} />}
						></SimpleActionsButton>
					)}
					{fileInputClickHandler && (
						<UnborderedButton
							onClick={fileInputClickHandler}
							style={{
								fontSize: theme.font.size.body,
								color: theme.colors.text.titles,
								height: 20,
								display: 'flex',
							}}
							icon={
								<UploadIcon
									style={{ width: '14px', color: theme.colors.text.titles }}
								/>
							}
						>
							Upload
						</UnborderedButton>
					)}
					{customTableButtons &&
						customTableButtons.map((button: any) => {
							return button.component
						})}
					{sendEmail && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<SimpleActionsButton
								// onClick={sendEmail}
								style={{
									display: 'flex',
									flexDirection: 'row',
									fontSize: theme.font.size.body,
									height: 20,
									color: theme.colors.text.titles,
								}}
								icon={<SendIcon style={{ scale: '1' }} />}
								label='Send'
								options={sendEmail}
							></SimpleActionsButton>
							{financeLastSend && (
								<div
									style={{
										position: 'relative',
										// left: '-10px',
										color: theme.colors.text.titles,
										fontSize: theme.font.size.body,
										width: '100px',
										marginTop: '4px',
									}}
								>
									{financeFinalSent
										? 'Final Numbers sent: '
										: 'Last Status sent: '}
									<span style={{ fontWeight: theme.font.weight.bold }}>
										{financeLastSend}
									</span>
								</div>
							)}
						</div>
					)}
				</div>
			</GridToolbarContainer>
		)
	},
)
export interface TotalRowProps {
	totalRow: { [key: string]: string | number | null }
	columns: string[]
	reports: any[]
	columnWidths: { [key: string]: number }
}

const TotalRowStyledTableCell = styled(TableCell)`
	&& {
		background-color: ${(props: any) => props.theme.colors.base.grey300};
		font-weight: bold;
		font-size: 0.9rem;
		color: ${(props: any) => props.theme.colors.text.titles};
		border-top: 2px solid ${(props: any) => props.theme.colors.base.grey900};
		border-bottom: 2px solid ${(props: any) => props.theme.colors.base.grey900};
		text-align: left;
		white-space: nowrap;
		padding-right: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 12px;

		position: sticky; // Make it sticky
		bottom: 0; // Stick to the bottom
		z-index: 1; // Ensure it's above other elements
	}
`
const TruncatedCell: React.FC<{ value: string | number | null }> = ({
	value,
}) => {
	const cellRef = useRef<HTMLDivElement>(null)
	const [isTruncated, setIsTruncated] = useState(false)

	useEffect(() => {
		const checkTruncation = () => {
			if (cellRef.current) {
				setIsTruncated(
					cellRef.current.scrollWidth > cellRef.current.clientWidth,
				)
			}
		}
		checkTruncation()
		window.addEventListener('resize', checkTruncation)

		return () => window.removeEventListener('resize', checkTruncation)
	}, [value])

	return (
		<Tooltip title={isTruncated ? String(value) : ''} arrow placement='top'>
			<div
				ref={cellRef}
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
			>
				{value}
			</div>
		</Tooltip>
	)
}
export const TotalRow: React.FC<TotalRowProps> = ({
	totalRow,
	columns,
	reports,
	columnWidths,
}) => {
	const theme = useTheme()

	// Get dynamic integer and float columns from the reportColumnMap
	const { integerColumns, floatColumns } =
		getIntegerAndFloatColumns(reportColumnMap)

	return (
		<StyledTableRow isTotalRow>
			{columns.map((columnKey, index) => {
				const normalizedColumnKey = columnKey.toLowerCase()

				// Match the key from totalRow, case-insensitive
				const matchingKey = Object.keys(totalRow).find(
					(key) => key.toLowerCase() === normalizedColumnKey,
				)
				const value = matchingKey ? totalRow[matchingKey] : ''

				// Format the value based on the column type (integer, float, etc.)
				const displayValue = roundColumnValuesNumbers(
					columnKey,
					value,
					integerColumns,
					floatColumns,
				)

				return (
					<StyledTableBodyCell
						key={index}
						isTotalRevenue={columnKey === reportColumnMap.totalRevenue}
						isTotalRow
						sx={{
							width: `${columnWidths[columnKey]}px`,
							minWidth: `${columnWidths[columnKey]}px`,
							maxWidth: `${columnWidths[columnKey]}px`,
							boxSizing: 'border-box',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							borderRight:
								index === columns.length - 1
									? 'none'
									: `1px solid ${theme.colors.border}`,
							padding: '8px',
						}}
					>
						<TruncatedCell value={displayValue} />
					</StyledTableBodyCell>
				)
			})}
		</StyledTableRow>
	)
}

interface ColumnFilterProps {
	columnKey: string
	filterValue: string
	onFilterChange: (key: string, value: string) => void
	onClearFilter: (key: string) => void
}

export const ColumnFilter: React.FC<ColumnFilterProps> = ({
	columnKey,
	filterValue,
	onFilterChange,
	onClearFilter,
}) => (
	<Box sx={{ marginTop: '4px' }}>
		<TextField
			value={filterValue}
			onChange={(e) => onFilterChange(columnKey, e.target.value)}
			variant='outlined'
			size='small'
			placeholder={`Filter ${columnKey}`}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton size='small' onClick={() => onClearFilter(columnKey)}>
							<ClearIcon fontSize='small' />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	</Box>
)

interface ColumnResizeHandleProps {
	onMouseDown: (event: React.MouseEvent) => void
}

export const ColumnResizeHandle: React.FC<ColumnResizeHandleProps> = ({
	onMouseDown,
}) => (
	<div
		onMouseDown={onMouseDown}
		style={{
			position: 'absolute',
			right: '-2px',
			top: 0,
			width: '6px',
			height: '100%',
			cursor: 'col-resize',
			zIndex: 1,
		}}
	/>
)
