import { Box, Typography, useTheme } from '@mui/material'
import { ReportItem } from '../reports/aggregated/ReportsStyled'

export const LabeledListSection = ({
	title,
	children,
	actionComponent,
}: {
	title: React.ReactNode
	children: React.ReactNode
	actionComponent?: React.ReactNode
}) => {
	const theme = useTheme()
	return (
		<ReportItem style={{ padding: '2rem 4rem' }}>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography
					variant='h6'
					sx={{
						color: theme.colors.text.titles,
						fontFamily: 'Roboto',
						marginBottom: '1rem',
					}}
				>
					{title}
				</Typography>
				{actionComponent}
			</Box>
			{children}
		</ReportItem>
	)
}
